<script setup lang="ts">
const story = await useAsyncStoryblok('homepage', {
  version: 'draft',
  resolve_relations: ['global_reference.reference'],
}, {
  resolveRelations: ['global_reference.reference'],
})
</script>

<template>
  <StoryblokComponent v-if="story" :blok="story.content" />
</template>
